<script setup lang="ts">
import { report, PhotoItem, Constatation } from '@/states/report';
import { computed } from 'vue';

const props = defineProps<{
  element: PhotoItem;
}>();

// constatations containing this Image
const constatations = computed<Constatation[]>(() => {
  return report.constatations.filter((constatation) => {
    return constatation.photos.some((photo) => photo.id === props.element.id);
  });
});
</script>
<template>
  <div class="container-fluid m-2">
    <img :src="element.downloadUrl" class="dr-card">
    <div class="mt-1">
      <div class="d-inline-flex input-group mb-1">
        <input @change="report.persist()" v-model="props.element.order" type="number" class="form-control"
          placeholder="N°" aria-label="N°">
        <select @change="report.persist()" v-model="element.part" class="form-select"
          aria-label="Default select example">
          <option value="Abords">Abords</option>
          <option value="Drain.">Drain.</option>
          <option value="Equip.">Equip.</option>
          <option value="Struct.">Struct.</option>
        </select>
        <select @change="report.persist()" v-model="element.localization" class="form-select"
          aria-label="Default select example">
          <option value="Mur">Mur</option>
          <option value="Sup.">Sup.</option>
          <option value="Cont.">Cont.</option>
          <option value="Autre">Autre</option>
        </select>
      </div>
      <div class="d-flex">
        <textarea @change="report.persist()" v-model="element.comment" placeholder="Commentaire" type="text"
          aria-label="Commentaire" class="form-control" style="min-width: 70%;"/>
        <div>
          <div class="d-flex gap-1 flex-wrap p-1">
            <span v-for="constatation in constatations" class="badge text-bg-info">{{ constatation.num }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.dr-card {
  max-height: 600px;
}
</style>
